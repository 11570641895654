import React from 'react';
import * as THREE from 'three';
import * as CANNON from 'cannon';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { isMobile } from 'react-device-detect';

import ObjectiveController from './utils/ObjectiveController.js';
import IsometricCamera from './utils/IsometricCamera.js';
import GetIntersectedSceneObjects from './utils/GetIntersectedSceneObjects.js';
import ModelRouter from './utils/ModelRouter.js';
import { CreateFpsCappedGameLoop, maxFps } from './utils/CreateFpsCap.js';
import { AddPhysicsModel, AddTextureModel } from './utils/ModelAdder.js';
import { BasicInputKeys, RouteDownKey, RouteUpKey, ClearInput } from './utils/InputManager.js';

import CirclePad from './components/CirclePad.js';
import LoadingScreen from './components/LoadingScreen.js';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadProgress: 0,
      hasCursorPointer: false
    };

    this.fpsCappedGameLoop = CreateFpsCappedGameLoop(this.animateFrame, maxFps);
    this.inputKeys = BasicInputKeys;
    this.modelRouter = ModelRouter;
    this.modelList = [];
  }

  initScene = () => {
    {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const fov = 30;
      const aspect = width / height;
      const near = 1;
      const far = 50;

      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color('#709E7A');
      this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      this.raycaster = new THREE.Raycaster();
      this.mouseVector = new THREE.Vector2();
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(width, height);
      this.canvasContainer.appendChild(this.renderer.domElement);
    }

    {
      const gravity = -9.83;
      this.world = new CANNON.World();
      this.world.gravity.set(0, gravity, 0);
      this.world.broadphase = new CANNON.NaiveBroadphase();
      this.world.solver.iterations = 10;
    }

    {
      const groundBody = new CANNON.Body({
        mass: 0,
        material: new CANNON.Material(),
        shape: new CANNON.Plane(),
        type: 2
      });

      groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
      this.world.addBody(groundBody);
    }
  };

  loadModels = () => {
    const loadingManager = new THREE.LoadingManager();
    const dracoLoaderPath = 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/';
    const gltfLoader = new GLTFLoader(loadingManager);
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(dracoLoaderPath);
    gltfLoader.setDRACOLoader(dracoLoader);

    loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      this.setState({ loadProgress: itemsLoaded / itemsTotal * 100 | 0 });
    };

    loadingManager.onLoad = () => {
      Object.values(this.modelRouter).forEach((model) => {
        if (model.type === 'character') {
          this.characterControls = new ObjectiveController({
            model: model,
            inputKeys: this.inputKeys,
            scene: this.scene,
            world: this.world,
            camera: this.camera
          });
          this.characterCamera = new IsometricCamera({
            camera: this.camera,
            target: this.characterControls
          });
        }
        else if (model.type === 'texture') AddTextureModel(model, this.scene);
        else if (model.type === 'physical') AddPhysicsModel(model, this.scene, this.world, this.modelList);
      });

      this.setState({ isLoading: false });
    };

    Object.values(this.modelRouter).forEach((model) => {
      gltfLoader.load(model.url, (gltf) => model.gltf = gltf);
    });
  };

  updatePhysics = (deltaTime) => {
    this.world.step(deltaTime);

    if (this.modelList.length) this.modelList.forEach(([ mesh, body ]) => {
      mesh.position.copy(body.position);
      mesh.quaternion.copy(body.quaternion);
    });
  };

  animateFrame = (deltaTime) => {
    deltaTime /= 1000;
    deltaTime = Math.min(deltaTime, 1 / 12);

    if (this.characterControls) this.characterControls.Update(deltaTime);
    if (this.characterCamera) this.characterCamera.Update(deltaTime);
    if (this.world && !this.state.isLoading) this.updatePhysics(deltaTime);

    this.renderer.render(this.scene, this.camera);
  };

  handleKeyDown = (event) => {
    RouteDownKey(event.keyCode, this.inputKeys);
  }

  handleKeyUp = (event) => {
    RouteUpKey(event.keyCode, this.inputKeys);
  }

  handleCanvasMousemove = (event) => {
    event.preventDefault();

    const intersectedObjects = GetIntersectedSceneObjects(
      this.scene,
      this.camera,
      this.raycaster,
      this.mouseVector,
      event.clientX,
      event.clientY
    );

    if (intersectedObjects.length && intersectedObjects[0].object.userData.hyperlink) this.setState({ hasCursorPointer: true });
    else this.setState({ hasCursorPointer: false });
  };

  handleCanvasClick = (event) => {
    event.preventDefault();

    const intersectedObjects = GetIntersectedSceneObjects(
      this.scene,
      this.camera,
      this.raycaster,
      this.mouseVector,
      event.clientX,
      event.clientY
    );

    if (intersectedObjects.length && intersectedObjects[0].object.userData.hyperlink) {
      const hyperlink = intersectedObjects[0].object.userData.hyperlink;
      ClearInput(this.inputKeys);
      window.open(hyperlink, '_blank');
    }
  };

  handleDefaultTouchStart = (event) => event.preventDefault();

  handleDefaultTouchMove = (event) => event.preventDefault();

  handleWindowResize = (event) => {
    setTimeout(() => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }, 100);
  };

  componentDidMount() {
    this.initScene();
    this.loadModels();
    this.renderer.setAnimationLoop(this.fpsCappedGameLoop.loop);

    window.addEventListener('resize', this.handleWindowResize, false);
    window.addEventListener('keydown', this.handleKeyDown, false);
    window.addEventListener('keyup', this.handleKeyUp, false);
    window.addEventListener('mousemove', this.handleCanvasMousemove, false);
    window.addEventListener('click', this.handleCanvasClick, false);

    if (isMobile) {
      window.addEventListener('touchstart', this.handleDefaultTouchStart, false);
      window.addEventListener('touchmove', this.handleDefaultTouchMove, { passive: false });
    }
  }

  componentWillUnmount() {
    this.renderer.setAnimationLoop(null);

    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('mousemove', this.handleCanvasMousemove);
    window.removeEventListener('click', this.handleCanvasClick);

    if (isMobile) {
      window.removeEventListener('touchstart', this.handleDefaultTouchStart);
      window.removeEventListener('touchmove', this.handleDefaultTouchStart);
    }
  }

  render() {
    return (
      <>
        <section
          ref={ref => this.canvasContainer = ref}
          id="canvas-container"
          className={
            (this.state.isLoading ? 'hidden' : 'shown') +
            (this.state.hasCursorPointer ? ' has-cursor-pointer' : '')
          }
        />
        <LoadingScreen
          isLoading={this.state.isLoading}
          loadProgress={this.state.loadProgress}
        />
        <CirclePad
          isLoading={this.state.isLoading}
          inputKeys={this.inputKeys}
        />
      </>
    );
  }
}

export default App;
