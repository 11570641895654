import React from 'react';
import { MobileView, isMobile } from 'react-device-detect';
import { Joystick } from 'react-joystick-component';
import { RouteJoystickMove, ClearInput } from '../utils/InputManager.js';

import './CirclePad.css';

const CirclePad = (props) => {
  if (!props.isLoading && isMobile) return (
    <MobileView>
      <div
        id="circle-pad"
        aria-hidden="true"
        draggable="false"
        onContextMenu={(event) => event.preventDefault()}
        onDragStart={(event) => event.preventDefault()}
      >
        <Joystick
          size={100}
          baseColor="grey"
          stickColor="black"
          move={(event) => RouteJoystickMove(event.x, event.y, props.inputKeys)}
          stop={(event) => ClearInput(props.inputKeys)}
        />
      </div>
    </MobileView>
  );

  return null;
};

export default CirclePad;
