// Libraries.
import * as THREE from 'three';
import * as CANNON from 'cannon';

// Helper classes.
import FiniteStateMachine from './FiniteStateMachine.js';
import AnimationState from './AnimationState.js';
import CharacterControllerProxy from './CharacterControllerProxy.js';

// Character & animation states setup.
class CharacterFSM extends FiniteStateMachine {
  constructor(proxy) {
    super();
    this._proxy = proxy;
    this._Init();
  }

  _Init() {
    this._AddAnimationState('Idle', IdleState);
    this._AddAnimationState('Walk', WalkState);
    this._AddAnimationState('Run', RunState);
  }
};

class IdleState extends AnimationState {
  get Name() {
    return 'Idle';
  }

  Enter(prevState) {
    const idleAction = this._parent._proxy._animations['Idle'].action;
    if (prevState) {
      const prevAction = this._parent._proxy._animations[prevState.Name].action;
      idleAction.time = 0.0;
      idleAction.enabled = true;
      idleAction.setEffectiveTimeScale(1.0);
      idleAction.setEffectiveWeight(1.0);
      idleAction.crossFadeFrom(prevAction, 0.75, true);
      idleAction.play();
    } else {
      idleAction.play();
    }
  }

  Exit() {}

  Update(deltaTime, inputKeys) {
    if (inputKeys.up) {
      if (inputKeys.down) return;
      this._parent.SetAnimationState('Walk');
    }
    else if (inputKeys.down) {
      if (inputKeys.up) return;
      this._parent.SetAnimationState('Walk');
    }
    else if (inputKeys.left) {
      if (inputKeys.right) return;
      this._parent.SetAnimationState('Walk');
    }
    else if (inputKeys.right) {
      if (inputKeys.left) return;
      this._parent.SetAnimationState('Walk');
    }
  }
};

class WalkState extends AnimationState {
  get Name() {
    return 'Walk';
  }

  Enter(prevState) {
    const currAction = this._parent._proxy._animations['Walk'].action;
    if (prevState) {
      const prevAction = this._parent._proxy._animations[prevState.Name].action;

      currAction.enabled = true;

      if (prevState.Name === 'Run') {
        const ratio = currAction.getClip().duration / prevAction.getClip().duration;
        currAction.time = prevAction.time * ratio;
      } else {
        currAction.time = 0.0;
        currAction.setEffectiveTimeScale(1.0);
        currAction.setEffectiveWeight(1.0);
      }

      currAction.crossFadeFrom(prevAction, 0.5, true);
      currAction.play();
    } else {
      currAction.play();
    }
  }

  Exit() {}

  Update(deltaTime, inputKeys) {
    if (inputKeys.up) {
      if (inputKeys.shift) this._parent.SetAnimationState('Run');
      else if (inputKeys.down) this._parent.SetAnimationState('Idle');
      return;
    } else if (inputKeys.down) {
      if (inputKeys.shift) this._parent.SetAnimationState('Run');
      else if (inputKeys.up) this._parent.SetAnimationState('Idle');
      return;
    } else if (inputKeys.left) {
      if (inputKeys.shift) this._parent.SetAnimationState('Run');
      else if (inputKeys.right) this._parent.SetAnimationState('Idle');
      return;
    } else if (inputKeys.right) {
      if (inputKeys.shift) this._parent.SetAnimationState('Run');
      else if (inputKeys.left) this._parent.SetAnimationState('Idle');
      return;
    }

    this._parent.SetAnimationState('Idle');
  }
};

class RunState extends AnimationState {
  get Name() {
    return 'Run';
  }

  Enter(prevState) {
    const currAction = this._parent._proxy._animations['Run'].action;
    if (prevState) {
      const prevAction = this._parent._proxy._animations[prevState.Name].action;

      currAction.enabled = true;

      if (prevState.Name === 'Walk') {
        const ratio = currAction.getClip().duration / prevAction.getClip().duration;
        currAction.time = prevAction.time * ratio;
      } else {
        currAction.time = 0.0;
        currAction.setEffectiveTimeScale(1.0);
        currAction.setEffectiveWeight(1.0);
      }

      currAction.crossFadeFrom(prevAction, 0.5, true);
      currAction.play();
    } else {
      currAction.play();
    }
  }

  Exit() {}

  Update(deltaTime, inputKeys) {
    if (inputKeys.up) {
      if (!inputKeys.shift) this._parent.SetAnimationState('Walk');
      else if (inputKeys.down) this._parent.SetAnimationState('Idle');
      return;
    } else if (inputKeys.down) {
      if (!inputKeys.shift) this._parent.SetAnimationState('Walk');
      else if (inputKeys.up) this._parent.SetAnimationState('Idle');
      return;
    } else if (inputKeys.left) {
      if (!inputKeys.shift) this._parent.SetAnimationState('Walk');
      else if (inputKeys.right) this._parent.SetAnimationState('Idle');
      return;
    } else if (inputKeys.right) {
      if (!inputKeys.shift) this._parent.SetAnimationState('Walk');
      else if (inputKeys.left) this._parent.SetAnimationState('Idle');
      return;
    }

    this._parent.SetAnimationState('Idle');
  }
};

// Controller setup.
class ObjectiveController {
  constructor(params) {
    this._model = params.model
    this._inputKeys = params.inputKeys;
    this._scene = params.scene;
    this._world = params.world;

    this._Init();
  }

  _Init() {
    this._animations = {};
    this._stateMachine = new CharacterFSM(new CharacterControllerProxy(this._animations));
    this._position = new THREE.Vector3();

    this._AddCharacter(this._model);
  }

  _AddCharacter(model) {
    const characterMesh = model.gltf.scene;
    this._targetMesh = characterMesh;
    this._targetMesh.scale.set(1.75, 1.75, 1.5);

    this._targetMesh.quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI * -0.25);

    this._mixer = new THREE.AnimationMixer(this._targetMesh);
    model.gltf.animations.forEach((animation) => {
      const clip = animation.name;
      const action = this._mixer.clipAction(animation);
      this._animations[animation.name] = {
        clip: clip,
        action: action
      };
    });

    const mass = 75;
    const size = 1;
    const characterShape = new CANNON.Sphere(size);
    const characterBody = new CANNON.Body({
      mass: mass,
      shape: characterShape,
      linearDamping: 0.99,
      angularDamping: 0.99,
      position: new CANNON.Vec3(12, size, -12),
      type: 1
    });

    this._targetBody = characterBody;

    this._world.addBody(this._targetBody);
    this._scene.add(this._targetMesh);

    this._stateMachine.SetAnimationState('Idle');
  }

  Update(deltaTime) {
    if (!this._targetMesh || !this._targetBody) return;

    this._stateMachine.Update(deltaTime, this._inputKeys);

    const controlObject = this._targetMesh;
    const _yAxis = new THREE.Vector3(0, 1, 0);
    const _Quaternion = new THREE.Quaternion();
    const _Rotation = controlObject.quaternion.clone();

    const animationSpeedAdj = 1.35;
    const turnSpeedAdj = 20;
    let xVelocity = 0;
    let zVelocity = 0;
    let baseSpeed = 2520 * deltaTime;
    const diagonalSpeedReduction = 0.72;

    if (this._inputKeys.shift) baseSpeed *= 1.9;

    if (this._inputKeys.up) {
      zVelocity = baseSpeed;
      if (this._inputKeys.down) zVelocity = 0;

      let direction = 0;
      if (this._inputKeys.left) {
        xVelocity = baseSpeed * diagonalSpeedReduction;
        zVelocity = baseSpeed * diagonalSpeedReduction;
        direction = Math.PI * 0.25;
      }
      if (this._inputKeys.right) {
        xVelocity = -baseSpeed * diagonalSpeedReduction;
        zVelocity = baseSpeed * diagonalSpeedReduction;
        direction = -Math.PI * 0.25;
      }

      if (controlObject.rotation.y !== direction) {
        _Quaternion.setFromAxisAngle(_yAxis, direction);
        _Rotation.rotateTowards(_Quaternion, deltaTime * turnSpeedAdj);
      }
    }

    if (this._inputKeys.down) {
      zVelocity = -baseSpeed;
      if (this._inputKeys.up) zVelocity = 0;

      let direction = Math.PI;
      if (this._inputKeys.left) {
        xVelocity = baseSpeed * diagonalSpeedReduction;
        zVelocity = -baseSpeed  * diagonalSpeedReduction;
        direction = Math.PI * 0.75;
      }
      if (this._inputKeys.right) {
        xVelocity = -baseSpeed * diagonalSpeedReduction;
        zVelocity = -baseSpeed * diagonalSpeedReduction;
        direction = -Math.PI * 0.75;
      }

      if (controlObject.rotation.y !== direction) {
        _Quaternion.setFromAxisAngle(_yAxis, direction);
        _Rotation.rotateTowards(_Quaternion, deltaTime * turnSpeedAdj);
      }
    }

    if (this._inputKeys.left) {
      xVelocity = baseSpeed;
      if (this._inputKeys.right) xVelocity = 0;

      let direction = Math.PI * 0.5;
      if (this._inputKeys.up) {
        xVelocity = baseSpeed * diagonalSpeedReduction;
        zVelocity = baseSpeed * diagonalSpeedReduction;
        direction = Math.PI * 0.25;
      }
      if (this._inputKeys.down) {
        xVelocity = baseSpeed * diagonalSpeedReduction;
        zVelocity = -baseSpeed * diagonalSpeedReduction;
        direction = Math.PI * 0.75;
      }

      if (controlObject.rotation.y !== direction) {
        _Quaternion.setFromAxisAngle(_yAxis, direction);
        _Rotation.rotateTowards(_Quaternion, deltaTime * turnSpeedAdj);
      }
    }

    if (this._inputKeys.right) {
      xVelocity = -baseSpeed;
      if (this._inputKeys.left) xVelocity = 0;

      let direction = -Math.PI * 0.5;
      if (this._inputKeys.up) {
        xVelocity = -baseSpeed * diagonalSpeedReduction;
        zVelocity = baseSpeed * diagonalSpeedReduction;
        direction = -Math.PI * 0.25;
      }
      if (this._inputKeys.down) {
        xVelocity = -baseSpeed * diagonalSpeedReduction;
        zVelocity = -baseSpeed * diagonalSpeedReduction;
        direction = -Math.PI * 0.75;
      }

      if (controlObject.rotation.y !== direction) {
        _Quaternion.setFromAxisAngle(_yAxis, direction);
        _Rotation.rotateTowards(_Quaternion, deltaTime * turnSpeedAdj);
      }
    }

    controlObject.quaternion.copy(_Rotation);

    const impulse = new CANNON.Vec3(xVelocity, 0, zVelocity);
    const worldPoint = this._targetBody.position;
    this._targetBody.applyImpulse(impulse, worldPoint);

    this._targetMesh.position.copy(this._targetBody.position);
    this._targetMesh.position.y -= 1;
    // this._targetMesh.quaternion.copy(this._targetBody.quaternion);

    this._position.copy(this._targetMesh.position);

    if (this._mixer) this._mixer.update(deltaTime * animationSpeedAdj);
  }

  get Position() {
    return this._position;
  }

  get Rotation() {
    if (!this._targetBody) return new THREE.Quaternion();

    return this._targetBody.quaternion;
  }
};

export default ObjectiveController;
