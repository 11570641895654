import { isMobile } from 'react-device-detect';

import BenchBack from '../assets/models/BenchBack.glb';
import BenchLeg from '../assets/models/BenchLeg.glb';
import BenchSeat from '../assets/models/BenchSeat.glb';
import BillboardDV from '../assets/models/BillboardDV.glb';
import BillboardGA from '../assets/models/BillboardGA.glb';
import BillboardGALA from '../assets/models/BillboardGALA.glb';
import BillboardGMB from '../assets/models/BillboardGMB.glb';
import Boombox from '../assets/models/Boombox.glb';
import Bottle from '../assets/models/Bottle.glb';
import Bread from '../assets/models/Bread.glb';
import Bush1 from '../assets/models/Bush1.glb';
import ButtonClick from '../assets/models/ButtonClick.glb';
import ButtonTap from '../assets/models/ButtonTap.glb';
import CardboardBatdog from '../assets/models/CardboardBatdog.glb';
import CardboardHatchling from '../assets/models/CardboardHatchling.glb';
import CardboardPlumber from '../assets/models/CardboardPlumber.glb';
import CardboardSupport from '../assets/models/CardboardSupport.glb';
import Cooler from '../assets/models/Cooler.glb';
import Credits from '../assets/models/Credits.glb';
import Die from '../assets/models/Die.glb';
import Domino from '../assets/models/Domino.glb';
import Earth from '../assets/models/Earth.glb';
import Fence1 from '../assets/models/Fence1.glb';
import Border03 from '../assets/models/Border03.glb';
import Border06 from '../assets/models/Border06.glb';
import Border07 from '../assets/models/Border07.glb';
import Border10 from '../assets/models/Border10.glb';
import Border13 from '../assets/models/Border13.glb';
import Bridge from '../assets/models/Bridge.glb';
import FlowerPink from '../assets/models/FlowerPink.glb';
import FlowerRed from '../assets/models/FlowerRed.glb';
import FlowerWhite from '../assets/models/FlowerWhite.glb';
import FlowerYellow from '../assets/models/FlowerYellow.glb';
import Grass1 from '../assets/models/Grass1.glb';
import InstructionJoystick from '../assets/models/InstructionJoystick.glb';
import InstructionKeyboard from '../assets/models/InstructionKeyboard.glb';
import Jam from '../assets/models/Jam.glb';
import Jupiter from '../assets/models/Jupiter.glb';
import LabelGitHub from '../assets/models/LabelGitHub.glb';
import LabelInstagram from '../assets/models/LabelInstagram.glb';
import LabelLinkedIn from '../assets/models/LabelLinkedIn.glb';
import LabelTwitter from '../assets/models/LabelTwitter.glb';
import LetterGroupFull from '../assets/models/LetterGroupFull.glb';
import LetterGroupStack from '../assets/models/LetterGroupStack.glb';
import LetterGroupDev from '../assets/models/LetterGroupDev.glb';
import LetterSingleA from '../assets/models/LetterSingleA.glb';
import LetterSingleB from '../assets/models/LetterSingleB.glb';
import LetterSingleE from '../assets/models/LetterSingleE.glb';
import LetterSingleL from '../assets/models/LetterSingleL.glb';
import LetterSingleR from '../assets/models/LetterSingleR.glb';
import LetterSingleS from '../assets/models/LetterSingleS.glb';
import LetterSingleT from '../assets/models/LetterSingleT.glb';
import LocationPin from '../assets/models/LocationPin.glb';
import LocationText from '../assets/models/LocationText.glb';
import Mars from '../assets/models/Mars.glb';
import Moon from '../assets/models/Moon.glb';
import PatchBlank from '../assets/models/PatchBlank.glb';
import PicnicCloth from '../assets/models/PicnicCloth.glb';
import Plaza from '../assets/models/Plaza.glb';
import ProjectDescriptionGA from '../assets/models/ProjectDescriptionGA.glb';
import ProjectDescriptionGMB from '../assets/models/ProjectDescriptionGMB.glb';
import ProjectDescriptionDV from '../assets/models/ProjectDescriptionDV.glb';
import Rock1 from '../assets/models/Rock1.glb';
import Shed from '../assets/models/Shed.glb';
import Shiba from '../assets/models/Shiba.glb';
import SignPost from '../assets/models/SignPost.glb';
import SignPostAbout from '../assets/models/SignPostAbout.glb';
import SignPostPlaypen from '../assets/models/SignPostPlaypen.glb';
import SignPostProjects from '../assets/models/SignPostProjects.glb';
import SodaCan from '../assets/models/SodaCan.glb';
import Sphere from '../assets/models/Sphere.glb';
import Stump1 from '../assets/models/Stump1.glb';
import Sword from '../assets/models/Sword.glb';
import Tile from '../assets/models/Tile1.glb';
import TileIconGitHub from '../assets/models/TileIconGitHub.glb';
import TileIconInstagram from '../assets/models/TileIconInstagram.glb';
import TileIconLinkedIn from '../assets/models/TileIconLinkedIn.glb';
import TileIconTwitter from '../assets/models/TileIconTwitter.glb';
import Timeline from '../assets/models/Timeline.glb';

const instructionModel = (isMobile ? InstructionJoystick : InstructionKeyboard);
const buttonVisit = (isMobile ? ButtonTap : ButtonClick);

const ModelRouter = {
  'character': {
    url: Shiba,
    type: 'character'
  },
  // 'PLAZA' area.
  'plaza-ground': {
    url: Plaza,
    type: 'texture',
    locationConfig: {
      position: [0, -0.05, 0],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'letter-1': {
    url: LetterSingleA,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [5, 0.8, 5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-2': {
    url: LetterSingleL,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [4, 0.8, 4],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-3': {
    url: LetterSingleB,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [3, 0.8, 3],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-4': {
    url: LetterSingleE,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [2, 0.8, 2],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-5': {
    url: LetterSingleR,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [1, 0.8, 1],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-6': {
    url: LetterSingleT,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [0, 0.8, 0],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-7': {
    url: LetterSingleS,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [-1.5, 0.8, -1.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-8': {
    url: LetterSingleA,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [-2.5, 0.8, -2.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-9': {
    url: LetterSingleR,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [-3.5, 0.8, -3.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'letter-10': {
    url: LetterSingleE,
    type: 'physical',
    scale: [0.7, 0.8, 0.6],
    locationConfig: {
      position: [-4.5, 0.8, -4.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.7, 0.8, 0.6],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'block-full': {
    url: LetterGroupFull,
    type: 'physical',
    scale: [1.65, 0.45, 0.4],
    locationConfig: {
      position: [0, 0.45, -5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [1.65, 0.45, 0.4],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'block-stack': {
    url: LetterGroupStack,
    type: 'physical',
    scale: [2, 0.45, 0.4],
    locationConfig: {
      position: [-2.75, 2, -7.75],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [2, 0.45, 0.4],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'block-dev': {
    url: LetterGroupDev,
    type: 'physical',
    scale: [1.25, 0.45, 0.4],
    locationConfig: {
      position: [-4.5, 0.45, -9.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [1.25, 0.45, 0.4],
      mass: 0.1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'sphere-1': {
    url: Sphere,
    type: 'physical',
    locationConfig: {
      position: [8, 10, -8]
    },
    physicsConfig: {
      size: [1],
      mass: 1,
      damping: 0.1,
      bodyShape: 'sphere',
      bodyType: 1,
      angularVelocity: [-2, 0, -2]
    }
  },
  // 'INSTRUCTIONS' quadrant.
  'instruction-board': {
    url: instructionModel,
    type: 'physical',
    locationConfig: {
      position: [14, 2.15, -9.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [3.25, 2, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'grass-by-board-right': {
    url: Grass1,
    type: 'texture',
    locationConfig: {
      position: [11.5, 0, -7.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'grass-by-board-left': {
    url: Grass1,
    type: 'texture',
    scale: [0.6, 0.6, 0.6],
    locationConfig: {
      position: [16.6, 0, -11.2],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'flower-by-board': {
    url: FlowerRed,
    type: 'texture',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [16.25, 0, -12],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.85
      }
    }
  },
  'rock-1': {
    url: Rock1,
    type: 'physical',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [10, 1.4, -18]
    },
    physicsConfig: {
      size: [1.5],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  'grass-by-rock': {
    url: Grass1,
    type: 'texture',
    scale: [0.5, 0.5, 0.75],
    locationConfig: {
      position: [8.5, 0, -18.5]
    }
  },
  'tile-1': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [19, -0.1, -19],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.65
      }
    }
  },
  'tile-2': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [21.5, -0.1, -21.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.7
      }
    }
  },
  'credits': {
    url: Credits,
    type: 'texture',
    locationConfig: {
      position: [28, -0.05, -28],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'stump-by-credits': {
    url: Stump1,
    type: 'physical',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [36, 0.75, -20],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [1.75, 1.75, 1.5, 12],
      mass: 0,
      damping: 0,
      bodyShape: 'cylinder',
      bodyType: 2
    }
  },
  // 'PLAYPEN' quadrant.
  'post-playpen': {
    url: SignPost,
    type: 'physical',
    locationConfig: {
      position: [8, 2, 12]
    },
    physicsConfig: {
      size: [0.5, 0.5, 3, 12],
      mass: 0,
      damping: 0,
      bodyShape: 'cylinder',
      bodyType: 2
    }
  },
  'sign-playpen': {
    url: SignPostPlaypen,
    type: 'physical',
    locationConfig: {
      position: [8, 4, 12],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [2.25, 1, 0.1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'grass-by-playpen-post': {
    url: Grass1,
    type: 'texture',
    scale: [0.5, 0.5, 0.75],
    locationConfig: {
      position: [7.5, 0, 11.75],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.2
      }
    }
  },
  'Die-1': {
    url: Die,
    type: 'physical',
    locationConfig: {
      position: [28, 10, 12]
    },
    physicsConfig: {
      size: [1, 1, 1],
      mass: 1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1,
      angularVelocity: [0, 0, 10]
    }
  },
  'Die-2': {
    url: Die,
    type: 'physical',
    locationConfig: {
      position: [28, 5, 12]
    },
    physicsConfig: {
      size: [1, 1, 1],
      mass: 1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1,
      angularVelocity: [10, 10, 0]
    }
  },
  'Die-3': {
    url: Die,
    type: 'physical',
    locationConfig: {
      position: [28, 1, 12]
    },
    physicsConfig: {
      size: [1, 1, 1],
      mass: 1,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-1': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [18, 1.5, 18],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-2': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [19, 1.5, 19],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-3': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [20, 1.5, 20],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-4': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [21, 1.5, 21],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-5': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [22, 1.5, 22],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-6': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [23, 1.5, 23],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'domino-7': {
    url: Domino,
    type: 'physical',
    locationConfig: {
      position: [24, 1.5, 24],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [1, 1.5, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'soda-1': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [10, 0.5, 25]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-2': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [11, 0.5, 25]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-3': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [10, 0.5, 26]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-4': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [12, 0.5, 25]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-5': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [11, 0.5, 26]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-6': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [10, 0.5, 27]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-7': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [13, 0.5, 25]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-8': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [12, 0.5, 26]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-9': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [11, 0.5, 27]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'soda-10': {
    url: SodaCan,
    type: 'physical',
    locationConfig: {
      position: [10, 0.5, 28]
    },
    physicsConfig: {
      size: [0.25, 0.25, 0.8, 24],
      mass: 1,
      damping: 0.2,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'cardboard-hatchling': {
    url: CardboardHatchling,
    type: 'physical',
    locationConfig: {
      position: [40, 1.6, -3],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [1.4, 1.4, 0.1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-hatchling-support-left': {
    url: CardboardSupport,
    type: 'physical',
    locationConfig: {
      position: [41.1, 1.4, -2]
    },
    physicsConfig: {
      size: [0.1, 1.4, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-hatchling-support-right': {
    url: CardboardSupport,
    type: 'physical',
    locationConfig: {
      position: [38.9, 1.4, -2]
    },
    physicsConfig: {
      size: [0.1, 1.4, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-plumber': {
    url: CardboardPlumber,
    type: 'physical',
    locationConfig: {
      position: [45, 1.6, -10],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [1.4, 1.4, 0.1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-plumber-support-left': {
    url: CardboardSupport,
    type: 'physical',
    locationConfig: {
      position: [46.1, 1.4, -9]
    },
    physicsConfig: {
      size: [0.1, 1.4, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-plumber-support-right': {
    url: CardboardSupport,
    type: 'physical',
    locationConfig: {
      position: [43.9, 1.4, -9]
    },
    physicsConfig: {
      size: [0.1, 1.4, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-batdog': {
    url: CardboardBatdog,
    type: 'physical',
    locationConfig: {
      position: [35, 1.6, -10],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [1.4, 1.4, 0.1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-batdog-support-left': {
    url: CardboardSupport,
    type: 'physical',
    locationConfig: {
      position: [36.1, 1.4, -9]
    },
    physicsConfig: {
      size: [0.1, 1.4, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'cardboard-batdog-support-right': {
    url: CardboardSupport,
    type: 'physical',
    locationConfig: {
      position: [33.9, 1.4, -9]
    },
    physicsConfig: {
      size: [0.1, 1.4, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  // 'ABOUT' quadrant.
  'post-about': {
    url: SignPost,
    type: 'physical',
    locationConfig: {
      position: [-11.75, 2, 7.25]
    },
    physicsConfig: {
      size: [0.5, 0.5, 3, 12],
      mass: 0,
      damping: 0,
      bodyShape: 'cylinder',
      bodyType: 2
    }
  },
  'sign-about': {
    url: SignPostAbout,
    type: 'physical',
    locationConfig: {
      position: [-11.75, 4, 7.25],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [2.25, 1, 0.1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'flower-by-about-post': {
    url: FlowerWhite,
    type: 'texture',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [-12.25, 0, 7.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.6
      }
    }
  },
  'timeline': {
    url: Timeline,
    type: 'texture',
    locationConfig: {
      position: [-27, -0.05, 12],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'location-pin': {
    url: LocationPin,
    type: 'physical',
    locationConfig: {
      position: [-30, 1.7, 23]
    },
    physicsConfig: {
      size: [1, 1.75, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'location-text': {
    url: LocationText,
    type: 'texture',
    locationConfig: {
      position: [-31.5, -0.05, 19.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'bridge': {
    url: Bridge,
    type: 'physical',
    locationConfig: {
      position: [-33, 1.5, 21],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.65
      }
    },
    physicsConfig: {
      size: [4, 1.5, 1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'logo-li': {
    url: TileIconLinkedIn,
    type: 'physical',
    locationConfig: {
      position: [-11.75, 1, 23.5]
    },
    physicsConfig: {
      size: [1, 1, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'label-li': {
    url: LabelLinkedIn,
    type: 'texture',
    locationConfig: {
      position: [-12.5, -0.05, 22.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'button-li': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-14, -0.05, 21],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    customData: {
      hyperlink: 'https://www.linkedin.com/in/albert-sare/'
    }
  },
  'logo-gh': {
    url: TileIconGitHub,
    type: 'physical',
    locationConfig: {
      position: [-15.75, 1, 27.5]
    },
    physicsConfig: {
      size: [1, 1, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'label-gh': {
    url: LabelGitHub,
    type: 'texture',
    locationConfig: {
      position: [-16.5, -0.05, 26.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'button-gh': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-18, -0.05, 25],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    customData: {
      hyperlink: 'https://github.com/TheSundayMailman/'
    }
  },
  'logo-ig': {
    url: TileIconInstagram,
    type: 'physical',
    locationConfig: {
      position: [-19.75, 1, 31.5]
    },
    physicsConfig: {
      size: [1, 1, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'label-ig': {
    url: LabelInstagram,
    type: 'texture',
    locationConfig: {
      position: [-20.5, -0.05, 30.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'button-ig': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-22, -0.05, 29],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    customData: {
      hyperlink: 'https://www.instagram.com/bokehheroes/'
    }
  },
  'logo-tw': {
    url: TileIconTwitter,
    type: 'physical',
    locationConfig: {
      position: [-23.75, 1, 35.5]
    },
    physicsConfig: {
      size: [1, 1, 0.1],
      mass: 0.01,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'label-tw': {
    url: LabelTwitter,
    type: 'texture',
    locationConfig: {
      position: [-24.5, -0.05, 34.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'button-tw': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-26, -0.05, 33],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    customData: {
      hyperlink: 'https://twitter.com/evilcellist/'
    }
  },
  'shed': {
    url: Shed,
    type: 'physical',
    locationConfig: {
      position: [-2.75, 6.1, 46.25],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [15, 6.1, 12.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'tile-by-shed': {
    url: Tile,
    type: 'texture',
    scale: [1.25, 1, 2],
    locationConfig: {
      position: [-12.5, -0.1, 36.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'grass-by-shed': {
    url: Grass1,
    type: 'texture',
    scale: [1, 0.5, 0.5],
    locationConfig: {
      position: [-9.5, 0, 35]
    }
  },
  // 'PROJECTS' quadrant.
  'post-projects': {
    url: SignPost,
    type: 'physical',
    locationConfig: {
      position: [-7, 2, -11.75]
    },
    physicsConfig: {
      size: [0.5, 0.5, 3, 12],
      mass: 0,
      damping: 0,
      bodyShape: 'cylinder',
      bodyType: 2
    }
  },
  'sign-projects': {
    url: SignPostProjects,
    type: 'physical',
    locationConfig: {
      position: [-7, 4, -11.75],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [2.25, 1, 0.1],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'stump-1': {
    url: Stump1,
    type: 'physical',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [-5, 0.75, -17]
    },
    physicsConfig: {
      size: [1.75, 1.75, 1.5, 12],
      mass: 0,
      damping: 0,
      bodyShape: 'cylinder',
      bodyType: 2
    }
  },
  'grass-by-stump': {
    url: Grass1,
    type: 'texture',
    locationConfig: {
      position: [-3.25, 0, -16],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.3
      }
    }
  },
  'flower-by-stump': {
    url: FlowerPink,
    type: 'texture',
    locationConfig: {
      position: [-6.75, 0, -18],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'billboard-ga': {
    url: BillboardGA,
    type: 'physical',
    locationConfig: {
      position: [-23, 3, -14],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [4.8, 3.2, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'description-ga': {
    url: ProjectDescriptionGA,
    type: 'texture',
    locationConfig: {
      position: [-21, -0.05, -21],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'button-ga': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-16, -0.05, -24],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    customData: {
      hyperlink: 'https://ads.google.com/home/'
    }
  },
  'billboard-gmb': {
    url: BillboardGMB,
    type: 'physical',
    locationConfig: {
      position: [-36, 3, -27],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [4.8, 3.2, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'billboard-gala': {
    url: BillboardGALA,
    type: 'physical',
    locationConfig: {
      position: [-42, 3, -33],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [4.8, 3.2, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'description-gmb': {
    url: ProjectDescriptionGMB,
    type: 'texture',
    locationConfig: {
      position: [-34, -0.05, -34],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'button-gmb': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-29, -0.05, -37],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    customData: {
      hyperlink: 'https://www.google.com/business/'
    }
  },
  'billboard-dv': {
    url: BillboardDV,
    type: 'physical',
    locationConfig: {
      position: [-49, 3, -40],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [4.8, 3.2, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'description-dv': {
    url: ProjectDescriptionDV,
    type: 'texture',
    locationConfig: {
      position: [-46, -0.05, -46],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'button-dv': {
    url: buttonVisit,
    type: 'texture',
    locationConfig: {
      position: [-42, -0.05, -50],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    customData: {
      hyperlink: 'https://dailyverse.netlify.app/'
    }
  },
  'earth': {
    url: Earth,
    type: 'physical',
    locationConfig: {
      position: [-50.3, 1.3, -42.3],
      rotation: {
        axis: [1, 0, 0],
        angle: Math.PI * 0.2
      }
    },
    physicsConfig: {
      size: [1.3],
      mass: 1,
      damping: 0.5,
      bodyShape: 'sphere',
      bodyType: 1
    }
  },
  'moon': {
    url: Moon,
    type: 'physical',
    locationConfig: {
      position: [-51.25, 0.5, -43.75]
    },
    physicsConfig: {
      size: [0.5],
      mass: 0.1,
      damping: 0.3,
      bodyShape: 'sphere',
      bodyType: 1
    }
  },
  'mars': {
    url: Mars,
    type: 'physical',
    locationConfig: {
      position: [-46, 1, -41],
      rotation: {
        axis: [1, 0, 0],
        angle: Math.PI * 0.2
      }
    },
    physicsConfig: {
      size: [1],
      mass: 1,
      damping: 0.4,
      bodyShape: 'sphere',
      bodyType: 1
    }
  },
  'jupiter': {
    url: Jupiter,
    type: 'physical',
    locationConfig: {
      position: [-53.5, 2, -43],
      rotation: {
        axis: [1, 0, 0],
        angle: Math.PI * 0.2
      }
    },
    physicsConfig: {
      size: [2],
      mass: 2,
      damping: 0.7,
      bodyShape: 'sphere',
      bodyType: 1
    }
  },
  'tile-to-picnic-1': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [-35, -0.1, -60],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.65
      }
    }
  },
  'tile-to-picnic-2': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [-31, -0.1, -59],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'tile-to-picnic-3': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [-27, -0.1, -57],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.85
      }
    }
  },
  'tile-to-picnic-4': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [-23.5, -0.1, -56],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'tile-to-picnic-5': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [-19, -0.1, -55],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.6
      }
    }
  },
  // North.
  'fence-1': {
    url: Fence1,
    type: 'physical',
    locationConfig: {
      position: [-4, 1.9, 15],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.9
      }
    },
    physicsConfig: {
      size: [3.5, 2, 0.35],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'grass-by-fence': {
    url: Grass1,
    type: 'texture',
    scale: [0.75, 1, 0.75],
    locationConfig: {
      position: [-0.3, 0, 15.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    }
  },
  'flower-by-fence': {
    url: FlowerYellow,
    type: 'texture',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [-0.55, 0, 15],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    }
  },
  // East.
  'bench-leg-1': {
    url: BenchLeg,
    type: 'physical',
    locationConfig: {
      position: [-18, 1.05, 3.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.5
      }
    },
    physicsConfig: {
      size: [0.15, 1.05, 1.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'bench-leg-2': {
    url: BenchLeg,
    type: 'physical',
    locationConfig: {
      position: [-18, 1.05, -3.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.5
      }
    },
    physicsConfig: {
      size: [0.15, 1.05, 1.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'bench-seat': {
    url: BenchSeat,
    type: 'physical',
    locationConfig: {
      position: [-17.75, 2.4, 0],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.5
      }
    },
    physicsConfig: {
      size: [4, 0.07, 1.245],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'bench-back': {
    url: BenchBack,
    type: 'physical',
    locationConfig: {
      position: [-19.1, 3.9, 0],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.5
      }
    },
    physicsConfig: {
      size: [4, 1.245, 0.07],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'grass-by-bench': {
    url: Grass1,
    type: 'texture',
    locationConfig: {
      position: [-19.5, 0, -4],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.25
      }
    }
  },
  // South.
  'bush-to-picnic-1': {
    url: Bush1,
    type: 'physical',
    scale: [2.75, 2.75, 2.75],
    locationConfig: {
      position: [2, 1.38, -35]
    },
    physicsConfig: {
      size: [2.75],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  'bush-to-picnic-2': {
    url: Bush1,
    type: 'physical',
    scale: [2, 2, 2],
    locationConfig: {
      position: [6, 1, -36],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [2],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  'bush-to-picnic-3': {
    url: Bush1,
    type: 'physical',
    scale: [3, 3, 3],
    locationConfig: {
      position: [-3, 1.5, -37],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.5
      }
    },
    physicsConfig: {
      size: [3],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  'bush-to-picnic-4': {
    url: Bush1,
    type: 'physical',
    scale: [2, 2, 2],
    locationConfig: {
      position: [-7, 1, -38],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.5
      }
    },
    physicsConfig: {
      size: [2],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  // West.
  'bush-1': {
    url: Bush1,
    type: 'physical',
    scale: [2.75, 2.75, 2.75],
    locationConfig: {
      position: [16.5, 1.38, 3],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [2.75],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  'bush-2': {
    url: Bush1,
    type: 'physical',
    scale: [2, 2, 2],
    locationConfig: {
      position: [17, 1, -1]
    },
    physicsConfig: {
      size: [2],
      mass: 0,
      damping: 0,
      bodyShape: 'sphere',
      bodyType: 2,
    }
  },
  // 'PICNIC' area.
  'picnic-cloth': {
    url: PicnicCloth,
    type: 'texture',
    locationConfig: {
      position: [-5.5, -0.05, -55],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.6
      }
    }
  },
  'cooler': {
    url: Cooler,
    type: 'physical',
    locationConfig: {
      position: [-4, 3, -50],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.85
      }
    },
    physicsConfig: {
      size: [1.5, 1.2, 1.1],
      mass: 475,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'boombox': {
    url: Boombox,
    type: 'physical',
    locationConfig: {
      position: [-10, 3, -54],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.65
      }
    },
    physicsConfig: {
      size: [1.8, 0.8, 0.6],
      mass: 1000,
      damping: 0.1,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'bottle-1': {
    url: Bottle,
    type: 'physical',
    locationConfig: {
      position: [-3, 1.2, -53]
    },
    physicsConfig: {
      size: [0.25, 0.25, 1.2, 12],
      mass: 1,
      damping: 0.5,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'jam-1': {
    url: Jam,
    type: 'physical',
    locationConfig: {
      position: [-5, 1.2, -55]
    },
    physicsConfig: {
      size: [0.35, 0.35, 0.7, 12],
      mass: 2,
      damping: 0.75,
      bodyShape: 'cylinder',
      bodyType: 1
    }
  },
  'bread-1': {
    url: Bread,
    type: 'physical',
    locationConfig: {
      position: [-4, 0.2, -57],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.35
      }
    },
    physicsConfig: {
      size: [1.25, 0.2, 0.25],
      mass: 0.1,
      damping: 0.9,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'bread-2': {
    url: Bread,
    type: 'physical',
    locationConfig: {
      position: [-4, 0.5, -57.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.65
      }
    },
    physicsConfig: {
      size: [1.25, 0.2, 0.25],
      mass: 0.1,
      damping: 0.9,
      bodyShape: 'box',
      bodyType: 1
    }
  },
  'bench-by-picnic-leg-1': {
    url: BenchLeg,
    type: 'physical',
    locationConfig: {
      position: [-14.75, 1.05, -40.75],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.15, 1.05, 1.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'bench-by-picnic-leg-2': {
    url: BenchLeg,
    type: 'physical',
    locationConfig: {
      position: [-19.75, 1.05, -45.75],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [0.15, 1.05, 1.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'bench-by-picnic-seat': {
    url: BenchSeat,
    type: 'physical',
    locationConfig: {
      position: [-17.1, 2.4, -43.4],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [4, 0.07, 1.245],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'bench-by-picnic-back': {
    url: BenchBack,
    type: 'physical',
    locationConfig: {
      position: [-18.05, 3.9, -42.45],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [4, 1.245, 0.07],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'flower-by-picnic-bench': {
    url: FlowerWhite,
    type: 'texture',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [-18.75, 0, -47.25],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    }
  },
  'blank-patch': {
    url: PatchBlank,
    type: 'texture',
    locationConfig: {
      position: [-26, -0.05, -79],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    }
  },
  'sword': {
    url: Sword,
    type: 'physical',
    locationConfig: {
      position: [-26, 2.5, -79],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    },
    physicsConfig: {
      size: [1, 4, 0.5],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'grass-by-sword': {
    url: Grass1,
    type: 'texture',
    locationConfig: {
      position: [-25, 0, -78],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI
      }
    }
  },
  'flower-by-sword': {
    url: FlowerYellow,
    type: 'texture',
    scale: [1.5, 1.5, 1.5],
    locationConfig: {
      position: [-24.75, 0, -79.25],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    }
  },
  'tile-to-credits-1': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [7, -0.1, -48],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.7
      }
    }
  },
  'tile-to-credits-2': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [11, -0.1, -46],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.6
      }
    }
  },
  'tile-to-credits-3': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [14, -0.1, -44],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.8
      }
    }
  },
  'tile-to-credits-4': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [17, -0.1, -42],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.5
      }
    }
  },
  'tile-to-credits-5': {
    url: Tile,
    type: 'texture',
    locationConfig: {
      position: [20, -0.1, -40],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.65
      }
    }
  },
  // Boundaries.
  'border-nw': {
    url: Border10,
    type: 'physical',
    locationConfig: {
      position: [31, 2, 31],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.75
      }
    },
    physicsConfig: {
      size: [50, 2, 0.45],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'border-ne-1': {
    url: Border07,
    type: 'physical',
    locationConfig: {
      position: [-29.5, 2, 41.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [35, 2, 0.45],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'border-ne-2': {
    url: Border06,
    type: 'physical',
    locationConfig: {
      position: [-54.15, 2, -25.75],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.75
      }
    },
    physicsConfig: {
      size: [30, 2, 0.45],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'border-se-1': {
    url: Border03,
    type: 'physical',
    locationConfig: {
      position: [-43.8, 2, 6],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.25
      }
    },
    physicsConfig: {
      size: [15, 2, 0.45],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'border-se-2': {
    url: Border07,
    type: 'physical',
    locationConfig: {
      position: [-50.4, 2, -71.8],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * 0.25
      }
    },
    physicsConfig: {
      size: [35, 2, 0.45],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  },
  'border-sw': {
    url: Border13,
    type: 'physical',
    locationConfig: {
      position: [20.5, 2, -50.5],
      rotation: {
        axis: [0, 1, 0],
        angle: Math.PI * -0.25
      }
    },
    physicsConfig: {
      size: [65, 2, 0.45],
      mass: 0,
      damping: 0,
      bodyShape: 'box',
      bodyType: 2
    }
  }
};

export default ModelRouter;
