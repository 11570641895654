import React from 'react';

import './LoadingScreen.css';

const LoadingScreen = (props) => {
  if (props.isLoading) return (
    <section id="loading-screen">
      <div>
        <div className="pulse-container">
          <p className="loading-text">Loading</p>
          <div className="pulse-dot pulse-dot-1" aria-hidden='true'></div>
          <div className="pulse-dot pulse-dot-2" aria-hidden='true'></div>
          <div className="pulse-dot pulse-dot-3" aria-hidden='true'></div>
        </div>
        <div className="progress-bar" aria-hidden='true'>
          <div className="progress-value" style={{ width: `${props.loadProgress}%` }}></div>
        </div>
      </div>
    </section>
  );

  return null;
};

export default LoadingScreen;
