class FiniteStateMachine {
  constructor() {
    this._states = {};
    this._currentState = null;
  }

  _AddAnimationState(name, type) {
    this._states[name] = type;
  }

  SetAnimationState(name) {
    const prevState = this._currentState;

    if (prevState) {
      if (prevState.Name === name) return;

      prevState.Exit();
    }

    const state = new this._states[name](this);

    this._currentState = state;
    state.Enter(prevState);
  }

  Update(deltaTime, input) {
    if (this._currentState) this._currentState.Update(deltaTime, input);
  }
}

export default FiniteStateMachine;
