const GetIntersectedSceneObjects = (scene, camera, raycaster, mouseVector, x, y) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  mouseVector.x = (x / width) * 2 - 1;
  mouseVector.y = -(y / height) * 2 + 1;

  raycaster.setFromCamera(mouseVector, camera);

  return raycaster.intersectObjects(scene.children, true);
};

export default GetIntersectedSceneObjects;
