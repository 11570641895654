class AnimationState {
  constructor(parent) {
    this._parent = parent;
  }

  Enter(prevState) {}

  Exit() {}

  Update(deltaTime, input) {}
};

export default AnimationState;
