const BasicInputKeys = {
  up: false,
  down: false,
  left: false,
  right: false,
  shift: false
};

const RouteDownKey = (keyCode, inputKeys) => {
  switch (keyCode) {
    case 87: // 'w'
      inputKeys.up = true;
      break;
    case 65: // 'a'
      inputKeys.left = true;
      break;
    case 83: // 's'
      inputKeys.down = true;
      break;
    case 68: // 'd'
      inputKeys.right = true;
      break;
    case 16: // 'shift'
      inputKeys.shift = true;
      break;
    default:
      inputKeys.up = false;
      inputKeys.down = false;
      inputKeys.left = false;
      inputKeys.right = false;
      inputKeys.shift = false;
  }
};

const RouteUpKey = (keyCode, inputKeys) => {
  switch (keyCode) {
    case 87: // 'w'
      inputKeys.up = false;
      break;
    case 65: // 'a'
      inputKeys.left = false;
      break;
    case 83: // 's'
      inputKeys.down = false;
      break;
    case 68: // 'd'
      inputKeys.right = false;
      break;
    case 16: // 'shift'
      inputKeys.shift = false;
      break;
    default:
      inputKeys.up = false;
      inputKeys.down = false;
      inputKeys.left = false;
      inputKeys.right = false;
      inputKeys.shift = false;
  }
};

const RouteJoystickMove = (x, y, inputKeys) => {
  const angle = Math.atan2(x, y);
  let direction = 'DOWN';
  if (-0.393 < angle && angle <= 0.393) direction = 'UP';
  else if (0.393 < angle && angle <= 1.179) direction = 'UP-RIGHT';
  else if (1.179 < angle && angle <= 1.965) direction = 'RIGHT';
  else if (1.965 < angle && angle <= 2.751) direction = 'DOWN-RIGHT';
  else if (-2.751 < angle && angle <= -1.965) direction = 'DOWN-LEFT';
  else if (-1.965 < angle && angle <= -1.179) direction = 'LEFT';
  else if (-1.179 < angle && angle <= -0.393) direction = 'UP-LEFT';

  inputKeys.shift = true;
  switch (direction) {
    case 'UP':
      inputKeys.up = true;
      inputKeys.down = false;
      inputKeys.left = false;
      inputKeys.right = false;
      break;
    case 'UP-RIGHT':
      inputKeys.up = true;
      inputKeys.down = false;
      inputKeys.left = false;
      inputKeys.right = true;
      break;
    case 'RIGHT':
      inputKeys.up = false;
      inputKeys.down = false;
      inputKeys.left = false;
      inputKeys.right = true;
      break;
    case 'DOWN-RIGHT':
      inputKeys.up = false;
      inputKeys.down = true;
      inputKeys.left = false;
      inputKeys.right = true;
      break;
    case 'DOWN':
      inputKeys.up = false;
      inputKeys.down = true;
      inputKeys.left = false;
      inputKeys.right = false;
      break;
    case 'DOWN-LEFT':
      inputKeys.up = false;
      inputKeys.down = true;
      inputKeys.left = true;
      inputKeys.right = false;
      break;
    case 'LEFT':
      inputKeys.up = false;
      inputKeys.down = false;
      inputKeys.left = true;
      inputKeys.right = false;
      break;
    case 'UP-LEFT':
      inputKeys.up = true;
      inputKeys.down = false;
      inputKeys.left = true;
      inputKeys.right = false;
      break;
    default:
      inputKeys.up = false;
      inputKeys.down = false;
      inputKeys.left = false;
      inputKeys.right = false;
      inputKeys.shift = false;
  }
};

const ClearInput = (inputKeys) => {
  inputKeys.up = false;
  inputKeys.down = false;
  inputKeys.left = false;
  inputKeys.right = false;
  inputKeys.shift = false;
};

export {
  BasicInputKeys,
  RouteDownKey,
  RouteUpKey,
  RouteJoystickMove,
  ClearInput
};
