import * as THREE from 'three';
import { isMobile } from 'react-device-detect';

class IsometricCamera {
  constructor(params) {
    this._camera = params.camera;
    this._target = params.target;
    this._currentPosition = new THREE.Vector3();
    this._currentLookat = new THREE.Vector3();
  }

  _CalculateIdealOffset(x, y, z) {
    const aspect = Math.round((window.innerWidth / window.innerHeight) * 10) / 10;
    if (aspect > 1.6) {
      let modifier = (aspect - 1.6) * 10;
      if (isMobile) modifier *= 1.5;
      y = Math.max(y - modifier, 10);
      z = Math.min(z + modifier, -10);
    }

    const idealPosition = new THREE.Vector3(x, y, z);
    idealPosition.add(this._target.Position);
    return idealPosition;
  }

  _CalculateIdealLookat(x, y, z) {
    const idealPosition = new THREE.Vector3(x, y, z);
    idealPosition.add(this._target.Position);
    return idealPosition;
  }

  Update(deltaTime) {
    const idealOffset = this._CalculateIdealOffset(0, 25, -25);
    const idealLookat = this._CalculateIdealLookat(0, 0, 0);

    const t = 3 * deltaTime;

    this._currentPosition.lerp(idealOffset, t);
    this._currentLookat.lerp(idealLookat, t);
    this._camera.position.copy(this._currentPosition);
    this._camera.lookAt(this._currentLookat);
  }
}

export default IsometricCamera;
