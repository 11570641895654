const CreateFpsCappedGameLoop = (gameLoop, fps = 120) => {
  let
    targetFps = 0,
    fpsInterval = 0,
    lastTime = 0,
    lastOverTime = 0,
    prevOverTime = 0,
    deltaTime = 0;

  const updateFps = (value) => {
    targetFps = value;
    fpsInterval = 1000 / targetFps;
  };

  updateFps(fps);

  return {
    get fps() {
      return targetFps;
    },
    set fps(value) {
      updateFps(value);
    },
    loop: (elapsedTime) => {
      deltaTime = elapsedTime - lastTime;

      if (deltaTime < fpsInterval) return;

      prevOverTime = lastOverTime;
      lastOverTime = deltaTime % fpsInterval;
      lastTime = elapsedTime - lastOverTime;
      deltaTime -= prevOverTime;

      return gameLoop(deltaTime);
    },
  };
};

const maxFps = 120;

export {
  CreateFpsCappedGameLoop,
  maxFps
};
