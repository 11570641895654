import * as THREE from 'three';
import * as CANNON from 'cannon';

const _generateBody = (physicsConfig) => {
  const {
    angularVelocity,
    bodyShape,
    bodyType,
    damping,
    mass,
    size,
  } = physicsConfig;

  let shape;
  if (bodyShape === 'sphere') {
    shape = new CANNON.Sphere(...size);
  } else if (bodyShape === 'box') {
    shape = new CANNON.Box(new CANNON.Vec3(...size));
  } else if (bodyShape === 'cylinder') {
    shape = new CANNON.Cylinder(...size);
    const translation = new CANNON.Vec3(0, 0, 0);
    const quaternion = new CANNON.Quaternion();
    quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    shape.transformAllPoints(translation, quaternion);
  }

  const body = new CANNON.Body({
    mass: mass,
    shape: shape,
    linearDamping: damping,
    angularDamping: damping,
    type: bodyType,
    sleepSpeedLimit: 1
  });
  if (angularVelocity) body.angularVelocity.set(...angularVelocity);

  return body;
};

const AddPhysicsModel = (model, scene, world, modelList) => {
  const { gltf, scale, locationConfig, physicsConfig } = model;
  const { position, rotation } = locationConfig;

  const mesh = gltf.scene;
  if (scale) mesh.scale.set(...scale);

  const body = _generateBody(physicsConfig);
  if (position) body.position.set(...position);
  if (rotation) body.quaternion.setFromAxisAngle(new CANNON.Vec3(...rotation.axis), rotation.angle);

  scene.add(mesh);
  world.addBody(body);

  if (physicsConfig.mass) modelList.push([mesh, body]);
  else {
    mesh.position.copy(body.position);
    mesh.quaternion.copy(body.quaternion);
  }
};

const AddTextureModel = (model, scene) => {
  const { gltf, scale, locationConfig, customData } = model;
  const { position, rotation } = locationConfig;

  const mesh = gltf.scene;
  if (scale) mesh.scale.set(...scale);
  if (position) mesh.position.set(...position);
  if (rotation) mesh.quaternion.setFromAxisAngle(new THREE.Vector3(...rotation.axis), rotation.angle);

  if (customData) mesh.children[0].children.forEach((child) => child.userData = { ...child.userData, ...customData });

  scene.add(mesh);
};

export { AddPhysicsModel, AddTextureModel };
